import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IText } from 'src/pages/Dashboard/TrainingService/Texts/lib/types';

interface TextsState {
  list: IText[];
  selection: number[];
};

const initialState: TextsState = {
  list: [],
  selection: [],
};

const textsSlice = createSlice({
  name: 'textsSelector',
  initialState,
  reducers: {
    setTextList: (state, action: PayloadAction<IText[]>) => {
      state.list = action.payload;
    },
    setSelectedTexts: (state, action: PayloadAction<number[]>) => {
      state.selection = action.payload;
    },
  },
});

export const { setTextList, setSelectedTexts } = textsSlice.actions;

export default textsSlice.reducer;