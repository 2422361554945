import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { findIndex } from "lodash";
import { addEdge, applyEdgeChanges, applyNodeChanges, Connection, Node, NodeChange, updateEdge } from "reactflow"
import { Entity, EntityIdentifier, IntentEntities, InteractionI, PreviousGraphState, NodeData, DialogState, DialogRaw } from "src/pages/Dashboard/TrainingService/types/dialogs";
import { updateInteraction } from "src/pages/Dashboard/TrainingService/utils/currentNode/newInteraction";
import { toggleIsWelcomeBlock } from "src/pages/Dashboard/TrainingService/utils/currentNode/toggleIsWelcomeBlock";
interface UpdateInteractionOnEditProp {
  interaction: InteractionI;
  onEdit: boolean;
}

interface UpdateInteractionIsNewProp {
  interaction: InteractionI;
  isNew: boolean;
  onEdit?: boolean;
}

const initialPrevGraphState: PreviousGraphState = {
  nodes: [],
  edges: [],
}

const initialDialogState: DialogRaw = {
  id: null,
  name: "",
  desc: "",
  dialog_blocks: [],
  flow_connections: [],
}

const initialNodeData: NodeData = {
  id: null,
  name: "",
  desc: "",
  interactions: [],
  flow_metadata: {},
  isNewNode: false,
  selectedInteraction: null,
  isWelcomeBlock: false,
  hasCloneNode: false,
}

const initialState: DialogState = {
  graph: {
    nodes: [],
    edges: [],
    selection: {
      nodes: [],
      edges: [],
    },
    ui: {
      graphLoading: false,
    },
  },
  previousGraphState: {
    nodes: [],
    edges: [],
  },
  sidebar: {
    ui: {
      isOpen: false,
      isLoading: false,
    },
    selectedNodeData: initialNodeData,
    previousNodeData: initialNodeData,
  },
  data: initialDialogState,
}

const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    nodeAdd: (state, action: PayloadAction<Node>) => {
      state.graph.nodes.push(action.payload)
    },
    deleteNode: (state, action: PayloadAction<string>) => {
      const nodeId = action.payload
      const newNodesArray = state.graph.nodes.filter((n) => n.id !== nodeId)
      state.graph.nodes = newNodesArray
    },
    updateNodes: (state, action: PayloadAction<any>)=> {
      state.graph.nodes = action.payload
    },
    updateEdges: (state, action: PayloadAction<any>) => {
      state.graph.edges = action.payload
    },
    nodesChange: (state, action: PayloadAction<NodeChange[]>) => {
      const newNodeArray = applyNodeChanges(action.payload, state.graph.nodes);
    
      state.graph.nodes = newNodeArray;
    },
    edgesChange: (state, action: PayloadAction<any>) => {
      const newEdgeArray = applyEdgeChanges(action.payload, state.graph.edges);
    
      state.graph.edges = newEdgeArray;
    },
    connectChange: (state, action: PayloadAction<Connection>) => {
      const newEdgeArray = addEdge(action.payload, state.graph.edges);
    
      state.graph.edges = newEdgeArray;
    },
    updateSingleEdge: (state, action: PayloadAction<any>) => {
      const {oldEdge, newConnection} = action.payload
      const newEdgesArray = updateEdge(oldEdge, newConnection, state.graph.edges)

      state.graph.edges = newEdgesArray
    },
    removeEdge: (state, action: PayloadAction<any>) => {
      const edge = action.payload
      const newEdgesArray = state.graph.edges.filter((e) => e.id !== edge.id)

      state.graph.edges = newEdgesArray
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.graph.ui.graphLoading = action.payload
    },
    resetPreviousGraphState: (state) => {
      state.previousGraphState = initialPrevGraphState
    },
    setNodesSelected: (state, action: PayloadAction<Node[]>) => {
      state.graph.selection.nodes = action.payload
    },
    setGraphNodes: (state, action) => {
      state.graph.nodes = action.payload;
    },
    setGraphEdges: (state, action) => {
      state.graph.edges = action.payload;
    },
    setBlockId: (state, action) => {
      const {nodeId, blockId} = action.payload

      const nodeIndex = findIndex(state.graph.nodes, {id: nodeId})
      state.graph.nodes[nodeIndex].data.id = blockId
    },
    setGraphSelection: (state, action) => {
      state.graph.selection = { ...state.graph.selection, ...action.payload };
    },
    setGraphUi: (state, action) => {
      state.graph.ui = { ...state.graph.ui, ...action.payload };
    },
    setPreviousGraphState: (state, action) => {
      state.previousGraphState = { ...state.previousGraphState, ...action.payload };
    },
    setSidebarSelectedNodeData: (state, action) => {
      state.sidebar.selectedNodeData = { ...state.sidebar.selectedNodeData, ...action.payload };
    },
    setSidebarPreviousNodeData: (state, action) => {
      state.sidebar.previousNodeData = { ...state.sidebar.previousNodeData, ...action.payload };
    },
    setSidebarUi: (state, action) => {
      state.sidebar.ui = { ...state.sidebar.ui, ...action.payload };
    },
    setDialogData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    setSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.sidebar.ui.isOpen = action.payload
    },
    updateNodeName: (state, action: PayloadAction<string>) => {
      const newName = action.payload
      state.sidebar.selectedNodeData.name = newName;
    },
    updateInteractions: (state, action: PayloadAction<InteractionI[]>) => {
      const newInteractionArray = action.payload
      state.sidebar.selectedNodeData.interactions = newInteractionArray
    },
    resetNode: (state) => {
      state.sidebar.selectedNodeData.name = ""
      state.sidebar.selectedNodeData.interactions = []
      state.sidebar.selectedNodeData.isNewNode = false
      state.sidebar.selectedNodeData.id = null
      state.sidebar.selectedNodeData.selectedInteraction = null
      state.sidebar.selectedNodeData.flow_metadata = {}
      state.sidebar.selectedNodeData.isWelcomeBlock = false
    },
    addInteraction: (state, action: PayloadAction<InteractionI>) => {
      state.sidebar.selectedNodeData.interactions.push(action.payload)
    },
    removeInteraction: (state, action: PayloadAction<number>) => {
      const newInteractionArray = state.sidebar.selectedNodeData.interactions.filter(interaction => interaction.id !== action.payload)
      state.sidebar.selectedNodeData.interactions = newInteractionArray
    },
    addEntity: (state, action: PayloadAction<EntityIdentifier>) => {
      const interactionIndex = state.sidebar.selectedNodeData.interactions.findIndex(interaction => interaction.id === action.payload.interactionId)
      const newEntity: Entity = action.payload.entity
      // @ts-ignore
      state.interactions[interactionIndex].node.entities.push(newEntity)
    },
    removeEntity: (state, action: PayloadAction<EntityIdentifier>) => {
      const interactionIndex = state.sidebar.selectedNodeData.interactions.findIndex(interaction => interaction.id === action.payload.interactionId)
      // @ts-ignore
      const newEntityArray = state.interactions[interactionIndex].node.entities.filter(entity => entity.name !== action.payload.entity.name)
      // @ts-ignore
      state.interactions[interactionIndex].node.entities = newEntityArray
    },
    updateInteractionName: (state, action) => {
      const index = findIndex(state.sidebar.selectedNodeData.interactions, { id: action.payload.interaction.id });
      const newItem = updateInteraction(action.payload.interaction, action.payload.name);
      const newInteractions = [
        ...state.sidebar.selectedNodeData.interactions.slice(0, index),
        newItem,
        ...state.sidebar.selectedNodeData.interactions.slice(index + 1),
      ];
      state.sidebar.selectedNodeData.interactions = newInteractions;
    },
    setExistingNode: (state, action: PayloadAction<NodeData>) => {
      state.sidebar.selectedNodeData.interactions = action.payload.interactions
      state.sidebar.selectedNodeData.name = action.payload.name
      state.sidebar.selectedNodeData.id = action.payload.id!
      state.sidebar.selectedNodeData.flow_metadata = action.payload.flow_metadata
      state.sidebar.selectedNodeData.isNewNode = action.payload.isNewNode
      state.sidebar.selectedNodeData.selectedInteraction = action.payload.selectedInteraction
      state.sidebar.selectedNodeData.isWelcomeBlock = action.payload.isWelcomeBlock
    },
    setIsNewNode: (state, action: PayloadAction<any>) => {
      state.sidebar.selectedNodeData.interactions = []
      state.sidebar.selectedNodeData.name = "nuevo nodo"
      state.sidebar.selectedNodeData.id = null
      state.sidebar.selectedNodeData.selectedInteraction = null
      state.sidebar.selectedNodeData.isNewNode = true
      state.sidebar.selectedNodeData.flow_metadata = action.payload
    },
    setCurrentInteraction: (state, action: PayloadAction<any>) => {
      state.sidebar.selectedNodeData.selectedInteraction = action.payload
    },
    setWelcomeBlock: (state, action: PayloadAction<boolean>) => {
      const newFlowMetadata = toggleIsWelcomeBlock(state.sidebar.selectedNodeData.flow_metadata, action.payload)
      state.sidebar.selectedNodeData.flow_metadata = newFlowMetadata
      state.sidebar.selectedNodeData.isWelcomeBlock = action.payload
    },
    updateSelectedEntities: (state, action: PayloadAction<IntentEntities>) => {
      const interactionIndex = state.sidebar.selectedNodeData.interactions.findIndex(interaction => interaction.id === action.payload.intentId)
      const newEntities: Entity[] = action.payload.entities
      // @ts-ignore
      state.sidebar.selectedNodeData.interactions[interactionIndex].node.entities = newEntities
    },
    setCurrentNodeOnEdit: (state, action: PayloadAction<UpdateInteractionOnEditProp>) => {
      const { interaction, onEdit } = action.payload;
      const updatedInteractions = state.sidebar.selectedNodeData.interactions.map(item => {
        if (item.id === interaction.id) {
          return { ...interaction, onEdit };
        } else {
          return item;
        }
      });
      return {
        ...state,
        sidebar: {
          ...state.sidebar,
          selectedNodeData: {
            ...state.sidebar.selectedNodeData,
            interactions: updatedInteractions
          }
        }
      };
    },    
    setCurrentNodeIsNew: (state, action: PayloadAction<UpdateInteractionIsNewProp>) => {
      const index = findIndex(state.sidebar.selectedNodeData.interactions, action.payload.interaction.id)
      const {isNew} = action.payload
      const newItem = {...action.payload.interaction, isNew}
      state.sidebar.selectedNodeData.interactions.splice(index!, 1, newItem)
    },
    onKeyDown: (state, action: PayloadAction<InteractionI>) => {
      const index = findIndex(state.sidebar.selectedNodeData.interactions, action.payload.id)
      const newItem = {...action.payload, onEdit: false, isNew: false}
      state.sidebar.selectedNodeData.interactions.splice(index!, 1, newItem)
    },
    setPrevNodeData: (state, action: PayloadAction<NodeData>) => {
      state.sidebar.previousNodeData = action.payload
    },
    resetPrevNodeData: (state) => {
      state.sidebar.previousNodeData = initialNodeData
    },
    /* Dialog */
    setDialog: (state, action: PayloadAction<DialogRaw>) => {
      state.data = action.payload
    },
    resetDialog: (state) => {
      state.data = initialDialogState
    },
    updateFlowConnections: (state, action: PayloadAction<number[][]>) => {
      state.data.flow_connections = action.payload
    },
  }
})

export const {
  nodeAdd,
  updateNodes,
  updateEdges,
  nodesChange,
  edgesChange,
  connectChange,
  updateSingleEdge,
  removeEdge,
  setLoading,
  resetPreviousGraphState,
  setSidebarOpen,
  updateNodeName,
  updateInteractions,
  resetNode,
  addInteraction,
  removeInteraction,
  addEntity,
  removeEntity,
  updateInteractionName,
  setExistingNode,
  setIsNewNode,
  setCurrentInteraction,
  setWelcomeBlock,
  updateSelectedEntities,
  setCurrentNodeOnEdit,
  setCurrentNodeIsNew,
  onKeyDown,
  setPrevNodeData,
  resetPrevNodeData,
  setDialog,
  resetDialog,
  setNodesSelected,
  updateFlowConnections,
  setGraphNodes,
  setGraphEdges,
  setBlockId,
  setGraphSelection,
  setGraphUi,
  setPreviousGraphState,
  setSidebarSelectedNodeData,
  setSidebarPreviousNodeData,
  setSidebarUi,
  setDialogData,
  
} = dialogSlice.actions

export default dialogSlice.reducer