import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface BotSelectorState {
  selectedClientBot: any;
}

const initialState: BotSelectorState = {
  selectedClientBot: null,
}

const clientBotSlice = createSlice({
  name: "botSelector",
  initialState,
  reducers: {
    selectClientBot: (state, action: PayloadAction<any>) => {
      state.selectedClientBot = action.payload
    }
  },
})

export const {selectClientBot} = clientBotSlice.actions

export default clientBotSlice.reducer