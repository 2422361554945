import {createSlice, PayloadAction} from "@reduxjs/toolkit"

interface Modal {
  isOpen: boolean;
  component: string | null;
  onChange: boolean;
  prevSelectedBot: string;
}
const NullModal: Modal = {
  isOpen: false,
  component: null,
  onChange: false,
  prevSelectedBot: "",
};

const initialState: Modal = NullModal

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setModal: (_, action: PayloadAction<Modal>) => action.payload,
    resetModal: () => NullModal,
    onChange: (state, action: PayloadAction<boolean>) => {
      state.onChange = action.payload
    },
    setPrevSelectedBot: (state, action: PayloadAction<string>) => {
      state.prevSelectedBot = action.payload
    },
  },
})

export const {setModal, resetModal, onChange, setPrevSelectedBot} = modalSlice.actions

export default modalSlice.reducer