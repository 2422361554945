import {createSlice, PayloadAction} from "@reduxjs/toolkit"

interface SidebarI {
  isOpen: boolean;
  cancelTrigger: number;
  deleteTrigger: number;
  selectDialogTrigger: number;
  newDialogTrigger: number;
  recentlyOpen: boolean;
  loadDialogTrigger: number;
}

const initialState: SidebarI = {
  isOpen: false,
  cancelTrigger: 0,
  deleteTrigger: 0,
  selectDialogTrigger: 0,
  newDialogTrigger: 0,
  recentlyOpen: false,
  loadDialogTrigger: 0,
}

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    cancelEditTrigger: (state) => {
      state.cancelTrigger += 1
    },
    deleteNodeTrigger: (state) => {
      state.deleteTrigger += 1
    },
    newDialogTrigger: (state) => {
      state.newDialogTrigger += 1
    },
    recentlyOpen: (state, action: PayloadAction<boolean>) => {
      state.recentlyOpen = action.payload
    },
  }
})

export const {setIsOpen, cancelEditTrigger, deleteNodeTrigger, newDialogTrigger, recentlyOpen} = sidebarSlice.actions

export default sidebarSlice.reducer