const roleHierarchy:any = {
  "ADMINISTRATOR": 'Super user',
  "CUSTOMER ADMIN": 'Admin',
  "CAMPAIGN CREATOR": 'Campaigns creator',
  "AGENT": 'Agent'
};


export function getUserType(arr: { id: number; name: string }[]) {
  for (const roleName in roleHierarchy) {
    const upperRoleName = roleName.toUpperCase();
    const foundUser = arr.find(user => user.name.toUpperCase() === upperRoleName);
    if (foundUser) return roleHierarchy[roleName];
  };
  return 'User';
};

