import { NavbarCardBox } from './NavBarStyles/NavbarCard.styles';
import { useAppSelector } from 'src/redux/hooks';
import MenuComponent from './Menu';
import { Avatar } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { getThemeFromUrl } from 'src/Themes/getThemeFromUrl';

export const NavBarCard = () => {

  const { user, isLoggedIn } = useAppSelector((state) => state.auth);
  const {color} = useContext(ThemeContext);

  return (
    <NavbarCardBox>
      {
        isLoggedIn
        &&
        <Avatar 
          sx={{ 
            width: 24, 
            height: 24, 
            fontSize: 14, 
            bgcolor:  color.navBarIcon, 
            color:  color.navBarTxt 
          }}>
          {user.first_name.charAt(0) + user.last_name.charAt(0)}
        </Avatar>
      }

      <MenuComponent />

    </NavbarCardBox>
  )
}
