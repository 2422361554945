import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';

export const useIsUserLoggedIn = () => {
  const { user, isLoggedIn, status } = useAppSelector((state) => state.auth);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [pathname, setPathname] = useState(window.location.pathname)

  useEffect(() => {
    if (localStorage.getItem('activeUser') !== null && user) {
      setIsUserLoggedIn(true);
    } else {
      setIsUserLoggedIn(false);
      setPathname('dashboard')
    }
  }, [isLoggedIn, status, user]);

  return { isUserLoggedIn, pathname };
};
