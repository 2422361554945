import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { showConnectionAlert } from "src/redux/slices/connectionSlice";
import { refreshTokenService } from "../loginServices/refreshTokenApiService.api";
import { setAccessToken } from "./setAccessToken";
import store from "src/redux/store";
import { showExpiredAlert } from "src/redux/slices/expiredSlice";
import { showInternalServerView } from "src/redux/slices/internalServerSlice";
import { getAccessToken } from "./getAccessToken";

export const instance: AxiosInstance = axios.create();

const addAuthorizationHeader = (config: AxiosRequestConfig, access: string): Promise<AxiosResponse> => {
  // Get the original request configuration
  const originalRequest: AxiosRequestConfig = config; 
  // Ensure headers exists, initializing as an empty object if not
  originalRequest.headers = originalRequest.headers || {}; 
  // Add Authorization header with JWT token
  originalRequest.headers["Authorization"] = `JWT ${access}`; 
  // Send the request with axios and return the response
  return axios(originalRequest); 
};

const handleRefreshError = async (error: AxiosError) => {
  // Get the current access token
  const accessToken = getAccessToken();
  try {
    const response: { data:{ access: string | null } } = await refreshTokenService();
    // Update the access token in the store
    setAccessToken(response.data.access??'');
    // Retry the original request with the new access token
    return addAuthorizationHeader(error.config, response.data.access??'');
  } catch (refreshError: unknown) { 
     // Check if the current access token matches the one retrieved again after attempting to refresh it.
    // This is done to ensure that the token has not been changed during the refresh process.
    if (accessToken === getAccessToken()) {
      store.dispatch(showExpiredAlert());
      return Promise.reject(refreshError);
    } else{
      // Retry the original request with the new access token
      return addAuthorizationHeader(error.config, getAccessToken() as string);
    };
  };
};

instance.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  async function (error: AxiosError) {
    // Check if the user is offline while making a request
    if (window.navigator.onLine === false) {
      store.dispatch(showConnectionAlert());
      return Promise.reject(error);
    }
    // Check if the error is a response error and not a network error
    if (error.response) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 401:
          // Check if the error is due to an expired token
          return await handleRefreshError(error);
        case 500:
          store.dispatch(showInternalServerView());
          return Promise.reject(error);
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers["Accept-Language"] = store.getState().language.selectedLanguage;
      return config;
    }
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  }
);