import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MultiValue } from 'react-select';
import { Action, ActionData } from 'src/pages/Dashboard/TrainingService/Actions/utils/types';
import { IText } from 'src/pages/Dashboard/TrainingService/Texts/lib/types';

interface ActionsState {
  list: Action[];
  selection: string[];
  edit: {
    originalData: ActionData | null;
    currentData: ActionData | null;
  }
};

const newAction: ActionData = {
  code: 'new_action',
  text: '',
  context: {},
  python_code: '',
  python_var: [],
  metadata_var: [],
  image: '',
};

const initialState: ActionsState = {
  list: [],
  selection: [],
  edit: {
    originalData: newAction,
    currentData: newAction,
  }
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    // Actions Edit
    setOriginalData: (state, action: PayloadAction<ActionData | null>) => {
      state.edit.originalData = action.payload;
      state.edit.currentData = action.payload;
    },
    resetActionsEdit: (state) => {
      state.edit.originalData = newAction;
      state.edit.currentData = newAction;
    },
    setActionName: (state, action: PayloadAction<string>) => {
      state.edit.currentData!.code = action.payload;
    },
    // Bot text
    setBotTextCustom: (state, action: PayloadAction<string>) => {
      state.edit.currentData!.text = action.payload;
    },
    setBotTextPredefined: (state, action: PayloadAction<IText | string>) => {
      if (typeof action.payload === 'string') {
        state.edit.currentData!.text = action.payload;
        return;
      } else {
        state.edit.currentData!.text = `TEXTID ${action.payload.response_id}`;
      }
    },
    // Context
    setContext: (state, action: PayloadAction<any>) => {
      state.edit.currentData!.context = action.payload;
    },
    // Python logic
    setPythonLogic: (state, action: PayloadAction<string>) => {
      state.edit.currentData!.python_code = action.payload;
    },
    // Variables
    setVariablesContext: (state, action: PayloadAction<MultiValue<any>>) => {
      state.edit.currentData!.python_var = action.payload.map(option => option.value) as any[];
    },
    setVariablesMetadata: (state, action: PayloadAction<MultiValue<any>>) => {
      state.edit.currentData!.metadata_var =  action.payload.map(option => option.value) as any[];
    },
    // Image
    setImageUrl: (state, action: PayloadAction<string>) => {
      state.edit.currentData!.image = action.payload;
    },
    // Actions List
    setActionsList: (state, action: PayloadAction<Action[]>) => {
      state.list = action.payload;
    },
    setSelectedActions: (state, action: PayloadAction<string[]>) => {
      state.selection = action.payload;
    },
  },
});

export const { 
  setOriginalData,
  setActionName,
  setBotTextCustom,
  setPythonLogic,
  setContext,
  setBotTextPredefined,
  setActionsList, 
  setSelectedActions, 
  setVariablesContext,
  setVariablesMetadata,
  setImageUrl,
  resetActionsEdit,
} = actionsSlice.actions;

export default actionsSlice.reducer;