import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/redux/hooks';
// import { hideInternalServerView } from 'src/redux/slices/internalServerSlice';
// import store from 'src/redux/store';
import styled from 'styled-components';
import ERRORSERVER from 'src/assets/error_server.svg';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  gap:1em;
  padding-bottom: 4rem;
  
  img{
    width: 20%;
     @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
      width: 50%;
     }

  }
`;


const ErrorMessage1 = styled.span`
  font-size: 32px;
  text-align: center;
  font-weight:${({ theme }) => theme.font.weight.xl};
  width: 60%;  
  @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    font-size: ${({ theme }) => theme.font.size.xl};
  }
`;

const ErrorMessage2 = styled.p`
  color:${({ theme }) => theme.color.txtLigth};
  font-size: ${({ theme }) => theme.font.size.m};
  text-align: center;
  font-weight:${({ theme }) => theme.font.weight.l};
  max-width: 320px;
  width: 70%;
  margin: 0;
  @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;

const InternalServerError = () => {

  const { t } = useTranslation();
  const { isInternalServerError } = useAppSelector((state: any) => state.internalServerError);

  useEffect(() => {
    if (!isInternalServerError) {
      window.location.href = '/login';
    }
  }, [isInternalServerError])

  // const handleClick = () => {
  //   store.dispatch(hideInternalServerView());

  // }

  return (
    <NotFoundWrapper>
      <img src={ERRORSERVER} alt='error' />
      <ErrorMessage1>
        {t('Internal server error')}
      </ErrorMessage1>
      <ErrorMessage2>
        {t('Server error 500. We fixing the problem. Please try again at a later stage')}
      </ErrorMessage2>
    </NotFoundWrapper>
  )
}

export default InternalServerError