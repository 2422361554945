import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IntentionsState, Intention } from "src/pages/Dashboard/TrainingService/Intentions/utils/types";
import { v4 as uuidv4 } from "uuid"

const initialState: IntentionsState = {
  list: [],
  selection: [],
}

const intentionsSlice = createSlice({
  name: "intentions",
  initialState,
  reducers: {
    setIntentionsList: (state, action: PayloadAction<Intention[]>) => {
      state.list = action.payload;
    },
    setSelectedIntentions: (state, action: PayloadAction<Intention[]>) => {
      state.selection = action.payload;
    },
    updateIntention: (state, action: PayloadAction<{id: string | number, intention: string, backendId?: number}>) => {
      if (action.payload.backendId) {
        // Replace the current id with the backend id
        const index = state.list.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = { ...state.list[index], id: action.payload.backendId, intention: action.payload.intention };
        }
      }
      const index = state.list.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.list[index] = { ...state.list[index], intention: action.payload.intention };
      }
    },
    updateTextIntention: (state, action: PayloadAction<{ id: string | number; text: string, backendId?: number }>) => {
      if (action.payload.backendId) {
        // Replace the current id with the backend id
        const index = state.list.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.list[index] = { ...state.list[index], id: action.payload.backendId, text: action.payload.text };
        }
      }
      const index = state.list.findIndex((item) => item.id === action.payload.id);
      if (index !== -1) {
        state.list[index] = { ...state.list[index], text: action.payload.text };
      }
    },
    addNewIntention: (state) => {
      const newIntention = {
        id: uuidv4(),
        text: "",
        intention: "",
      };

      state.list.push(newIntention);
    },
    deleteIntention: (state, action: PayloadAction<string | number>) => {
      const index = state.list.findIndex((item) => item.id === action.payload);
      if (index !== -1) {
        state.list.splice(index, 1);
      }
    },
  },
});

export const {
  setIntentionsList, 
  setSelectedIntentions, 
  updateIntention, 
  addNewIntention,
  updateTextIntention,
  deleteIntention,
} = intentionsSlice.actions;

export default intentionsSlice.reducer;