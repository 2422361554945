import { createSlice } from '@reduxjs/toolkit';

const connectionSlice = createSlice({
  name: 'connection',
  initialState: {
    isConnectionAlertVisible: false,
  },
  reducers: {
    showConnectionAlert: state => {
      state.isConnectionAlertVisible = true;
    },
    hideConnectionAlert: state => {
      state.isConnectionAlertVisible = false;
    },
  },
});

export const { showConnectionAlert, hideConnectionAlert } = connectionSlice.actions;
export default connectionSlice.reducer;
