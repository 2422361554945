
import { AppRouter } from '../routes/AppRouter.router';
import { ThemeProvider } from 'styled-components';
import { getThemeFromUrl } from "./getThemeFromUrl";
import { theme } from "./theme";
import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux/hooks';
import { GlobalStyle } from './globalStyle';
import { selectThemeBasedOnMode } from './selectThemeBasedOnMode';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { createMuiThemeFromTheme } from './createMuiThemeFromTheme';


export const ThemeCtxProvider = () => {
  
  const Theme = getThemeFromUrl()
  const { selectedTheme } = useAppSelector((state: any) => state.theme);
  const [darkMode, setDarkMode] = useState(localStorage.getItem('dark-mode') === "true" ? true : false)
  const muiTheme = createMuiThemeFromTheme(Theme);

  useEffect(() => {
    if (localStorage.getItem('dark-mode') === "true") {
      setDarkMode(true)      
    }else{
      setDarkMode(false)
    }
  }, [selectedTheme])  

  return (
    <ThemeProvider theme={theme[selectThemeBasedOnMode(Theme,darkMode)]}>
      <GlobalStyle />
      <MuiThemeProvider theme={muiTheme}>
        <AppRouter />
      </MuiThemeProvider>
    </ThemeProvider>

  )
}

