import { Navigate, Outlet } from "react-router-dom";

type props = {
  isUserLoggenIn: Boolean;
  pathname: string;
  querys: string;
}

const PublicRoutes = ({ isUserLoggenIn, pathname, querys }: props): JSX.Element => {
  const lastVisitedURL = localStorage.getItem('lastVisitedURL');
  if (lastVisitedURL && isUserLoggenIn) {
    localStorage.removeItem('lastVisitedURL');
    return <Navigate to={lastVisitedURL} />;
  } else if (isUserLoggenIn) {
    return <Navigate to={(pathname.includes('dashboard')) ? pathname + querys : 'dashboard'} />;
  }
  return <Outlet />
}

export default PublicRoutes; 
