import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface BotSelectorState {
  Bot: any;
}

const initialState: BotSelectorState = {
  Bot: null,
}

const botSlice = createSlice({
  name: "botSelector",
  initialState,
  reducers: {
    selectBot: (state, action: PayloadAction<any>) => {
      state.Bot = action.payload
    }
  },
})

export const {selectBot} = botSlice.actions

export default botSlice.reducer