import { combineReducers, configureStore } from '@reduxjs/toolkit';
import tasksReducer from "./slices/tasksSlice";
import connectionReducer from "./slices/connectionSlice";
import internalServerReducer from "./slices/internalServerSlice";
import expiredReducer from "./slices/expiredSlice";
import authReducer from "./slices/auth/authSlice";
import BotReducer from "./slices/botSlice";
import entitiesReducer from "./slices/entitiesSlice"
import sidebarReducer from "./slices/trainingSidebarSlice"
import dialogModalReducer from "./slices/dialogModalSlice"
import dialogReducer from "./slices/trainingService/dialogSlice"
import clientBotReducer from "./slices/trainingService/clientBotSlice"
import textsReducer from "./slices/trainingService/textsSlice"
import actionsReducer from "./slices/trainingService/actionsSlice"
import intentionsReducer from "./slices/trainingService/intentionsSlice"
import languageReducer from "./slices/LanguageSlice";
import themeReducer from "./slices/theme.slice";

const trainingServiceReducer = combineReducers({
  dialog: dialogReducer,
  clientBot: clientBotReducer,
  texts: textsReducer,
  actions: actionsReducer,
  intentions: intentionsReducer,
});

const store = configureStore({
  reducer: {
    auth: authReducer,
    connection: connectionReducer,
    internalServerError: internalServerReducer,
    expired: expiredReducer,
    language: languageReducer,
    botSelected : BotReducer,
    tasks: tasksReducer,
    entities: entitiesReducer,
    sidebar: sidebarReducer,
    dialogModal: dialogModalReducer,
    trainingService: trainingServiceReducer,
    intentions: intentionsReducer,
    theme: themeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck:false
    })
});
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
