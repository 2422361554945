import { BM_API_URL } from "../utils/api";
import { instanceLogin } from "../utils/axios.service.login";


export const getUserService = async(access:string) => {
  const login_url = `${BM_API_URL}/auth/user/`;   
  const response = instanceLogin.get(
    login_url,
     {
      headers: {
        'Authorization': `JWT ${access}`,
      },
    }
  );
  
  return response 
};

