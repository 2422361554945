import { domainName } from "./Objects/domainName";

export function getThemeFromUrl() {
  const href:string = window.location.href;

  switch (true) {
    case href.includes(domainName.inceptia):
      return "inceptia";
    case href.includes(domainName.chattigo):
      return "chattigo";
    case href.includes(domainName.pelikanus):
      return "pelikanus";
    default:
      return "inceptia";
  }
}
