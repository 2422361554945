import { createGlobalStyle, } from 'styled-components';
import { FontStack } from './fontFamily';


export const GlobalStyle = createGlobalStyle`

  :root {
    --font: ${FontStack};
  }
  body {
    max-width: 3840px;
    margin: 0 auto;
    padding: 0;
    font-family: var(--font);
    text-decoration: none;
    overflow: hidden;
  }
  html {
    box-sizing: border-box;  
    font-family: var(--font);
    font-size: 16px;
    scroll-behavior: smooth;
  }
 
  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }  
  h1 {margin: 0;font-size: 2rem;}
  h2 {margin: 0;font-size: 1.5rem;}
  h3 {margin: 0;font-size: 1.25rem;}
  h4 {margin: 0;font-size: 1rem;}
  h5 {margin: 0;font-size: 0.85rem;}
  h6 {margin: 0;font-size: 0.7rem;}
  img {
    height: auto;
  }  
`