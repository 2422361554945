import { ThemeObject } from "../interface";
import  { statics } from "./StaticsComponents";

export const inceptiaDark:ThemeObject = {
  color:  {
    navBar: "#1d2125",
    navBarIcon: "#ffffff",
    navBarTxt: "#ffffff",
    sideBar: "#1d2125",
    txt: '#ffffff',
    txtDark: '#37303E',
    txtLigth: '#37303e99',
    bkg: "#1d2125",
    bkgLight: "#FAF9F9",
    btnPrimary: '#1976d2',
    btnTextPrimary: '#ffff',
    btnSecondary: '#7e9ab7',
    error: '#d32f2f',
    btnGreen: '#19a119',
    border: '#ffffff',
    white: '#ffff',
    item: '#c1c1c1',
    link: '#ffffff',
    linkHover: '#1a73e824',
    itemSelected: '#0052CC',
    itemSelectedText: '#ffffff',    
    },
    ...statics
}