import styled from "styled-components"

export const NavbarContainer = styled.nav`
  background-color:${({ theme }) => theme.color.navBar} ;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.navbar};
  height: 3.5rem;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  `
export const NavbarWrapper = styled.div`
  width: 100%;
  height:100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;  
  flex-wrap: nowrap;
  `
export const NavbarBox = styled.div`
  margin: 0 1.25em ;
  color: white ;
  font-size: ${({ theme }) => theme.font.size.m};
  display: flex;
  align-items: center;
  .link{
    display: flex;
    align-items: center;
    color: white ;
    text-decoration:none;
  }
`
export const NavbarText = styled.span`
  font-size: ${({ theme }) => theme.font.size.l} ;
  font-weight: ${({ theme }) => theme.font.weight.xl};
  @media only screen and (max-width: 768px) {
    display: none;
  } 
`
export const NavbarLogo = styled.img<{height:string}>`
  height:${({height}) => height };
`
export const Hr = styled.div`
  hr{
    height:1.875rem;
    width:0.125rem;
    border-width:0;
    /* color:#000; */
    background-color:${({ theme }) => theme.color.bkgLight};
    margin: 0 1.25em ;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`
