import { Navigate, Outlet } from "react-router-dom";

type props = {
  isUserLoggenIn: Boolean;
  user: any
}

const PrivateRoutes = ({ user, isUserLoggenIn }: props): JSX.Element => {
  if (!isUserLoggenIn || !user || Object.keys(user).length === 0) {
    return <Navigate to='login' replace />
  };
  return <Outlet />
};

export default PrivateRoutes;
