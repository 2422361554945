import { getAccessToken, instance } from "src/services/utils";
import { BM_API, BM_API_URL, BM_API_VERSION } from "../../utils/api";
  
interface PutTasksProps {
  formData: any,
  id: string,
  signal?: AbortSignal
};

export const putTasksService = async({ formData , id,signal }:PutTasksProps ) => {    
  const baseURL = `${BM_API_URL}/${BM_API}/${BM_API_VERSION}/tasks/${id}/`;
  const response = await instance.put(
    `${baseURL}`,
    formData,
    { 
      headers:{
        'Authorization': `jwt ${getAccessToken()} `,
        'Content-Type': 'multipart/form-data'
      },
      signal 

    }
  );
  return response;
};