import { Provider } from "react-redux";
import { createRoot } from 'react-dom/client';
import store from './redux/store';
import {ThemeCtxProvider} from "./Themes/ThemeCtxProvider";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <ThemeCtxProvider/>
  </Provider>
);

