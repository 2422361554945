import { ThemeObject } from "../interface";
import  { statics } from "./StaticsComponents";


export const pelikanus:ThemeObject = {
    color:  {
      navBar: "#000000",
      navBarIcon: "#E7EBEF",
      navBarTxt: "#37303E",
      sideBar: "#E7EBEF",
      txt: '#37303E',
      txtDark: '#37303E',
      txtLigth: '#37303e99',
      bkg: "#f4f6f8",
      bkgLight: "#FAF9F9",
      btnTextPrimary: '#00FFFF',
      btnPrimary: '#37303E',
      btnSecondary: '#7e9ab7',
      error: '#d32f2f',
      btnGreen: '#19a119',
      border: '#0000001f',
      white: '#ffff',
      item: '#45526E',
      link: '#1976d2',
      linkHover: '#c6d3e061',
      itemSelected: '#37303e2b',
      itemSelectedText: '#37303E',
    },
    ...statics    

  }