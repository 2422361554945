import { BM_API, BM_API_URL, BM_API_VERSION, getAccessToken, instance } from "src/services/utils";

export const getCampaignDetails = async(id:string|undefined) => {
  const baseURL =`${BM_API_URL}/${BM_API}/${BM_API_VERSION}/campaigns/${id}/`;
  const response = await instance.get(
    baseURL,
    {
      headers:{
        'Authorization': `jwt ${getAccessToken()} `
        }
    }
    );
  return response

};