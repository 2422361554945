import axios from "axios";
import { UserModel } from "src/models/user.model";
import { BM_API_URL } from "../utils/api";
import { instanceLogin } from "../utils/axios.service.login";
import { getTemporalAccessToken } from "../utils/getTemporalAccessToken";


export const loginApiService = async({username,password}: UserModel) => {
  const login_url = `${BM_API_URL}/auth/login/`; 
  const response = instanceLogin.post(
    login_url,
    {
      email: username,
      password 
    },
  );  
  return response 
};

export const loginApiService2FA = async({username,password}: UserModel) => {
  const login_url = `${BM_API_URL}/auth/login/`; 
  const response = instanceLogin.post(
    login_url,
    {
      email: username,
      password 
    },
    {
      headers: {
        'Authorization': `JWT ${getTemporalAccessToken()}`,
      },
    }
  );
  
  return response 
};