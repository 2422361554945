import styled from 'styled-components';


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap:1em;
  margin-bottom: 4rem;
  img{
    width: 20%;
    @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
      width: 50%;
    }    
  }
`;

export const ErrorMessage1 = styled.span`
  font-size: 32px;
  text-align: center;
  font-weight:${({ theme }) => theme.font.weight.xl};
  width: 60%;
  
  @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    font-size: ${({ theme }) => theme.font.size.xl};
  }
`;

export const ErrorMessage2 = styled.p`
  color:${({ theme }) => theme.color.txtLigth};
  font-size: ${({ theme }) => theme.font.size.m};
  text-align: center;
  font-weight:${({ theme }) => theme.font.weight.l};
  max-width: 320px;
  width: 60%;
  margin: 0;
  @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    font-size: ${({ theme }) => theme.font.size.s};
  }
`;