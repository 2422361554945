import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import { Entity } from "src/pages/Dashboard/TrainingService/types/dialogs"

const initialState: Entity[] = []

const entitiesSlice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    updateEntities: (_, action: PayloadAction<Entity[]>) => {
      return action.payload
    },
  }
})

export const {updateEntities} = entitiesSlice.actions

export default entitiesSlice.reducer