
export const getRefreshToken = (): string | null => {
  const activeUserData = localStorage.getItem('activeUser');
  if (activeUserData) {
    const activeUser = JSON.parse(activeUserData);
    return activeUser.refresh || null;
  }
  return null;
};

 