import { getAccessToken } from "../utils";
import { BM_API_URL } from "../utils/api";
import { instanceLogin } from "../utils/axios.service.login";

export const logoutApiService = async() => {
  const all = false;
  const logout_url = `${BM_API_URL}/auth/logout/?all=${all}`; 
  const response = await instanceLogin.post(
    logout_url,
    {},
     {
      headers: {
        Authorization: `jwt ${getAccessToken()}`
      }
    }
  )
  return response
};
