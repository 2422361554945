import { getAccessToken } from "../utils";
import { BM_API_URL } from "../utils/api";
import { getRefreshToken } from "../utils/getRefreshToken";
import { instanceLogin } from "../utils/axios.service.login";

export const refreshTokenService = async () => {
  const data = {
    access: getAccessToken()
  };
  const refresh_url = `${BM_API_URL}/auth/token/refresh/`;
  
  const response = await instanceLogin.post(
    refresh_url,
    data,
    {
      headers: {
        'Authorization': `JWT ${getRefreshToken()}`,
      },
    }
  );
  return response;
};
