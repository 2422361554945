import { getAccessToken, instance } from "src/services/utils";
import { BM_API, BM_API_URL, BM_API_VERSION } from "../../utils/api";

interface GetTasksProps {
  id:number | string,
  signal?:AbortSignal

}
  
export const getTasksService = async({id,signal}:GetTasksProps ) => {   
  const baseURL = `${BM_API_URL}/${BM_API}/${BM_API_VERSION}/tasks/${id}/`;
  const response = await instance.get(
    baseURL,
    { 
      headers:{
        'Authorization': `jwt ${getAccessToken()} `
      },
      signal
    }
  );
  return response;
};
