import styled from "styled-components";

export const NavbarCardBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center ;
  width: 100%;
  align-items: center;
`
export const NavbarCardBoxArrow = styled.div<{ active: boolean }>`
  cursor: ${props => (props.active ? '' : "pointer")};
  height: 4.75rem ;
  margin-left:1em ;
  display: flex; 
  align-items: center;
  transition: all 0.3s ease;
  &:before{
    content: "";
    transition: all 0.3s ease;
  }
  
  img{    
    transform: ${props => (props.active ? 'translateY(0)' : "translateY(-5px)")};  
    background-color: ${props => (props.active && props.theme.color.btnPrimary)};
    padding: 0.375em ;
    border-radius: 8px;
    transition: all 0.3s ease;
    margin-top: 0.313em ;
  }
  
  &:hover:before {
  img {
    color:${({ theme }) => theme.color.btnPrimary};
  }  
}
&:hover img {
  background-color: ${({ theme }) => theme.color.btnPrimary};
  transform: translateY(0);
}
 @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    margin-left:0 ;
  }

`

export const NavbarCardBoxItemsColumn = styled.div`
  width: 100%;
  /* height: 2.188rem; */
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  /* justify-content: center ; */
  /* align-items: center; */
  /* margin-right: 0.938em ;   */
  @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    margin-right: 0;
  }
`

export const NavbarCardBoxItemsRow = styled.div`
  padding: 4px 16px ;
  padding-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: center ; */
  /* align-items: center; */
  @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    margin-right: 0;
  }
`
export const NavBarDetails = styled.div`  
  display: flex;
  flex-direction: column;
  gap:.2em;
  @media only screen and (max-width: ${props => props.theme.breakPoint.sm}) {
    display: none;
  }
`
export const StyledName = styled.div`
  white-space: break-spaces;
  font-weight: ${({ theme }) => theme.font.weight.xl};
  font-size: ${({ theme }) => theme.font.size.m};

`
export const StyledEmail = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.m};
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.color.txt};
`

export const StyledRol = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.m};
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.color.txt};
`