import { getAccessToken, instance } from "src/services/utils";
import { BM_API, BM_API_URL, BM_API_VERSION } from "../../utils/api";

interface PostTasksProps {
  formData: any,
  signal: AbortSignal
};

export const postTasksService = async({formData,signal}:PostTasksProps ) => {   
  const baseURL = `${BM_API_URL}/${BM_API}/${BM_API_VERSION}/tasks/`;
  const response = await instance.post(
    baseURL,
    formData,

    { 
      headers:{
        'Authorization': `jwt ${getAccessToken()}`,
        'Content-Type': 'multipart/form-data'
      },
      signal
    }
  );
  return response;
};
