import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { showInternalServerView } from "src/redux/slices/internalServerSlice";
import store from "src/redux/store";
export const instanceLogin: AxiosInstance = axios.create();

instanceLogin.interceptors.response.use(
  async(response:AxiosResponse) => {
    return response;
  },
  async function (error: AxiosError) {
    if (error.response) {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 500:         
          store.dispatch(showInternalServerView());
          return Promise.reject(error); 
        default:
          break;
      }
    }
    return Promise.reject(error);
  }
);

instanceLogin.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers["Accept-Language"] = store.getState().language.selectedLanguage;
      return config;
    }
  },
  async (error: AxiosError) => {
    return Promise.reject(error);
  }
);