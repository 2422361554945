import { InteractionI, InteractionType } from "../../types/dialogs";
import { frontendID } from "./frontendID";

/* Helper function for Redux state (currentNodeSlice.ts), returns a new updated interaction item */

export const updateInteraction = (interaction: InteractionI, name: string) => {
  if (interaction.type === "action") {
    const newAction: InteractionI = {
      ...interaction,
      node: {
        action: {
          name,
        }
      }
    }

    return newAction
  } else {
    const newIntent: InteractionI = {
      ...interaction,
      node: {
        intent: {
          name,
        },
        entities: interaction.node.entities
      }
    }

    return newIntent
  }
}

/* Helper function for Redux state (currentNodeSlice.ts), returns a new empty interaction item */

export const newInteraction = (type: InteractionType) => {
  if (type === "action") {
    const newAction: InteractionI = {
      id: frontendID(),
      type: type,
      onEdit: true,
      isNew: true,
      node: {
        action: {
          name: "",
        }
      }
    }

    return newAction
  } else {
    const newIntent: InteractionI = {
      id: frontendID(),
      type: type,
      onEdit: true,
      isNew: true,
      node: {
        intent: 
        {
          name: "",
        },
        entities: [],
      }
    }
    
    return newIntent
  }
}
