import { createSlice } from '@reduxjs/toolkit';

const internalServerSlice = createSlice({
  name: 'internalServerError',
  initialState: {
    isInternalServerError: false,
  },
  reducers: {
    showInternalServerView: state => {
      state.isInternalServerError = true;
    },
    hideInternalServerView: state => {
      state.isInternalServerError = false;
    },
  },
});

export const { showInternalServerView, hideInternalServerView } = internalServerSlice.actions;
export default internalServerSlice.reducer;
