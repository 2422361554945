
export const statics = {
    font:{
        weight:{
            m: "400",
            l: "500",
            xl: "700",
        },
        size:{
            xs: '0.75rem',
            s: '0.875rem',
            m: '1rem',
            l: '1.125rem',
            xl: '1.5rem',
        },
    },
    breakPoint:{
        xs: '480px',
        sm: '768px',
        md: '992px',
        lg: '1200px',
        xl: '1600px',
    },
    zIndex:{
        tabletr: 15,
        tableth: 16,
        header: 20,
        dropdown: 25,
        modal: 30,
        sidebar: 40,
        navbar: 50,
        overlay: 60,
        drawer:1200
    },
    border:{
        primary: "1px solid hsl(0, 0%, 80%)",
        error: "1px solid #d32f2f"
    },   
    borderRadius: "8px",
    boxShadow: "rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px"

    
}

export {}; // Exportación vacía para indicar que este archivo es un módulo
