import { ThemeObject } from "../interface";
import  { statics } from "./StaticsComponents";


export const chattigo:ThemeObject = {
    color:  {
      navBar: "#f4f6f8",
      navBarIcon: "#37303E",
      navBarTxt: "#f4f6f8",
      sideBar: 'linear-gradient(180deg, #da4d4f 0%, #f17369 39%, #f19b5c 100%)',
      txt: '#37303E',
      txtDark: '#37303E',
      txtLigth: '#37303e99',
      bkg: "#f4f6f8",
      bkgLight: "#FAF9F9",
      btnPrimary: '#FF6C01',
      btnTextPrimary: '#ffff',
      btnSecondary: '#fba289',
      error: '#d32f2f',
      btnGreen: '#19a119',
      border: '#0000001f',
      white: '#ffff',
      item: '#FAF9F9',
      link: '#FF6C01',
      linkHover: '#faf9f810',
      itemSelected: '#faf9f921',
      itemSelectedText: '#FAF9F9',
    },
    ...statics
  }