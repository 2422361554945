import { createSlice } from '@reduxjs/toolkit';

const expiredSlice = createSlice({
  name: 'expired',
  initialState: {
    isExpiredAlertVisible: false,
  },
  reducers: {
    showExpiredAlert: state => {
      state.isExpiredAlertVisible = true;
    },
    hideExpiredAlert: state => {
      state.isExpiredAlertVisible = false;
    },
  },
});

export const { showExpiredAlert, hideExpiredAlert } = expiredSlice.actions;
export default expiredSlice.reducer;
