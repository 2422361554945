import styled from "styled-components";

export const Container = styled.div`
  /* background-color: ${({ theme }) => theme.color.bkgLight};; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;  
`
export const LoaderStyled = styled.div`   

  --uib-size: 80px;
 --uib-speed: 2s;
 --uib-color: ${({ theme }) => theme.color.btnPrimary};
 --uib-color2: ${({ theme }) => theme.color.btnTextPrimary};
 position: relative;
 height: var(--uib-size);
 width: var(--uib-size);


&:before , &:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid var(--uib-color2) ;
  background-color: var(--uib-color);
  animation: pulse7132 var(--uib-speed) linear infinite;
  transform: scale(0);
  opacity: 0;

}
&:after{
   animation-delay: calc(var(--uib-speed) / -2);
  }

  @keyframes pulse7132 {
 0% {
  transform: scale(0);
  opacity: 1;
 }

 100% {
  transform: scale(1);
  opacity: 0;
 }
}
`

export const LoaderFallback = () => {
  return (
    <Container>
      <LoaderStyled />
    </Container>
  )
}
